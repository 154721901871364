import Onboarding from "./Onboarding";
import Profile from "./Profile";
import EditPersonalInfo from "./EditPersonalInfo";
import ExchangeRates from "./ExchangeRates";
import CardMenu from "./CardMenu";
import SignIn from "./SignIn";
import Dashboard from "./Dashboard";
import Deposits from "./Deposits";
import Trade from "./Trade-";
import ContractManual from "./ContractManual";
import Follow from "./Follow";
import BotActionLog from "./BotActionLog";
import Profit from "./Profit";
import ProfitDetails from "./ProfitDetails";
import BotStrategy from "./BotStrategy";
import BotOrder from "./BotOrder";
import MyAssets from "./MyAssets";
import Commission from "./Commission";
import TradeHistory from "./TradeHistory";
import EditStrategy from "./EditStrategy";
import TransactionSetting from "./TransactionSetting";
import Notification from "./Notification";
import More from "./More";
import SignInCode from "./SignInCode";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSentEmail from "./ForgotPasswordSentEmail";
import NewPassword from "./NewPassword";
import SignUp from "./SignUp";
import SignUpAccountCreated from "./SignUpAccountCreated";
import VerifyYourPhoneNumber from "./VerifyYourPhoneNumber";
import ConfirmationCode from "./ConfirmationCode";
import OpenMoneybox from "./OpenMoneybox";
import OpenNewLoan from "./OpenNewLoan";
import Statistics from "./Statistics";
import StatisticsChooseCard from "./StatisticsChooseCard";
import FAQ from "./FAQ";
import PrivacyPolicy from "./PrivacyPolicy";
import Payments from "./Payments";
import FundTransfer from "./FundTransfer";
import TransactionHistory from "./TransactionHistory";
import TransactionDetails from "./TransactionDetails";
import PaymentFailed from "./PaymentFailed";
import OpenAccount from "./OpenAccount";
import IncomeRanking from "./IncomeRanking";
import MyEarnings from "./MyEarnings";
import SystemSetting from "./SystemSetting";
import UpdateAPIBinding from "./UpdateAPIBinding";
import Transfer from "./Transfer";
import Withdraw from "./Withdraw";
import Deposit from "./Deposit";
import Convert from "./Convert";
import AnnouncementList from "./AnnouncementList";
import Referral from "./Referral";
import Tutorials from "./Tutorials";
import Materials from "./Materials";
import API from "./API";
import AccountSetting from "./AccountSetting";
import MaterialList from "./MaterialList";
import TutorialList from "./TutorialList";
import Duobao from "./Duobao";
import Community from "./Community";
import Log from "./Log";
import ChangePassword from "./ChangePassword";
import ChangeSecurityPin from "./ChangeSecurityPin";
import AnnouncementDetails from "./AnnouncementDetails";
import TutorialDetails from "./TutorialDetails";
import Ranking from "./Ranking";
import TokenList from "./TokenList";
import StrategySelection from "./StrategySelection";
import OnTrendMargin from "./OnTrendMargin";
import MarginSetting from "./MarginSetting";
import KlineSetting from "./KlineSetting";
import ProfitMode from "./ProfitMode";
import ProfitSetting from "./ProfitSetting";
import MemberPurchase from "./MemberPurchase";
import MemberPurchase2 from "./MemberPurchase";
import StrategyProfit from "./StrategyProfit";
import PurchaseHistory from "./PurchaseHistory";
import DepositHistory from "./DepositHistory";
import TransferHistory from "./TransferHistory";
import ConverHistory from "./ConvertHistory";
import WithdrawHistory from "./WithdrawHistory";
import StartBotDetails from "./StartBotDetails";
import CommissionDetails from "./CommissionDetails";
import PurchaseSuccess from "./PurchaseSuccess";
import TakeProfitModeSetting from "./TakeProfitModeSettings";
import TakeProfitSettings from "./TakeProfitSettings";
import StrategyDetails from "./StrategyDetails";
import MyPackages from "./MyPackages";
import PackageRoi from "./PackageRoi";
import Membership from "./Membership";
import { BotDetails, TradingBots } from "./Trade";
import Stake from './Stake'
import AllowedDeduction from './AllowedDeduction'
import BotSettings from './BotSettings'
import CreditAlert from "./CreditAlert"
import FollowStrategy from "./FollowStrategy"
import CopyTrading from "./CopyTrade";
import CopyTradeDetails from "./CopyTrade/detail";
import CopyTradeActionLog from "./CopyTrade/actionLogs"
import FXTradingAccounts from "./FXTradingAccounts";
import FXTradingAccount from "./FXTradingAccount";
import FXTradingCopyTrade from "./FXTradingCopyTrade";
import FXTradingCopyTradeDetail from "./FXTradingCopyTradeDetail";
import TermsAndConditions from "./TermsAndConditions";
import Policy from './Policy';
import TradingBotHistory from "./TradingBotHistory";

const screens = {
  Policy,
  TermsAndConditions,
  FXTradingCopyTradeDetail,
  FXTradingCopyTrade,
  FXTradingAccount,
  FXTradingAccounts,
  CopyTradeActionLog,
  CopyTradeDetails,
  CopyTrading,
  FollowStrategy,
  PackageRoi,
  MyPackages,
  Ranking,
  Commission,
  MyEarnings,
  AllowedDeduction,
  CreditAlert,
  Follow,
  BotSettings,
  Profit,
  Stake,
  ProfitDetails,
  BotActionLog,
  BotStrategy,
  BotOrder,
  Duobao,
  Onboarding,
  Profile,
  EditPersonalInfo,
  ExchangeRates,
  CardMenu,
  SignIn,
  Dashboard,
  Deposits,
  Trade,
  StrategyDetails,
  ContractManual,
  MyAssets,
  TradeHistory,
  EditStrategy,
  TransactionSetting,
  Notification,
  More,
  SignInCode,
  ForgotPassword,
  ForgotPasswordSentEmail,
  NewPassword,
  SignUp,
  SignUpAccountCreated,
  VerifyYourPhoneNumber,
  ConfirmationCode,
  OpenMoneybox,
  OpenNewLoan,
  Statistics,
  StatisticsChooseCard,
  FAQ,
  PrivacyPolicy,
  Payments,
  FundTransfer,
  TransactionHistory,
  TransactionDetails,
  PaymentFailed,
  OpenAccount,
  IncomeRanking,
  SystemSetting,
  UpdateAPIBinding,
  Transfer,
  Withdraw,
  Deposit,
  Convert,
  AnnouncementList,
  Referral,
  Tutorials,
  Materials,
  API,
  AccountSetting,
  MaterialList,
  TutorialList,
  Community,
  Log,
  ChangePassword,
  ChangeSecurityPin,
  AnnouncementDetails,
  TutorialDetails,
  TradingBots,
  TokenList,
  StrategySelection,
  OnTrendMargin,
  MarginSetting,
  KlineSetting,
  ProfitMode,
  ProfitSetting,
  Membership,
  MemberPurchase,
  MemberPurchase2,
  StrategyProfit,
  PurchaseHistory,
  DepositHistory,
  TransferHistory,
  ConverHistory,
  WithdrawHistory,
  StartBotDetails,
  CommissionDetails,
  PurchaseSuccess,
  TakeProfitModeSetting,
  TakeProfitSettings,
  BotDetails,
  TradingBotHistory,
};

export { screens };