import React, {useEffect, useState} from "react";
import {ScrollView, TouchableOpacity, View, FlatList} from "react-native";
import {components} from "../components";
import {ApButton, ApSafeAreaView} from "../components/v1";
import {ApSearchInput} from "../components/v1/input";
import {ApRegCheckInput} from "../components/v1/input/reg_check";
import {theme} from "../constants";
import {svg} from "../svg";
import {ApText} from "../components/v1/typography";

interface IProps {
  navigation?: any;
  multiSelect?: boolean;
  route?: any[];
  onDimiss?: (seleted: any) => void;
}

let dataList: any = [];
const StrategySelection: React.FC<IProps> = ({
  navigation,
  onDimiss,
  multiSelect = true,
  route,
}) => {
  const {strategies, StrategyListSelected = [], onReturn} = route.params || {};
  const [selected, setSelected] = useState<any[]>();
  const [data, setData] = useState([]);
  const [selectedStrategies, setSelectedStrategies] = useState([]);

  useEffect(() => {
    dataList = strategies?.sort((a, b) => b.id - a.id);

    setData(dataList || []);
    setSelected(
      !!selectedStrategies.length
        ? selectedStrategies
        : StrategyListSelected || []
    );
  }, []);

  console.log("selectedStrategies", data);
  const handleSelected = (item: any) => {
    if (!multiSelect) {
      setSelected([item]);
      handleConfirm([item]);
    }
    if (isSelected(item))
      setSelected(selected.filter((s: any) => s.id !== item.id));
    else setSelected([...selected, item]);
  };

  const isSelected = (item: any) => {
    if (!selected?.length) return false;

    return (
      selected.find((s: any) => s.id === item.id || s.label === item.label) !==
      undefined
    );
  };

  const handleConfirm = () => {
    setSelectedStrategies(selected);

    if (onReturn) {
      onReturn(selected);
    }

    if (navigation && navigation.canGoBack()) navigation.goBack();
  };

  const handleSearch = (val: string) => {
    setData(
      dataList.filter((d: any) => d?.label?.toLowerCase().search(val) !== -1)
    );
  };

  const renderHeader = () => {
    return (
      <components.Header
        title={"Strategy"}
        goBack={true}
        titleTranlateKey="Strategy"
        customRightIcon={
          <TouchableOpacity
            style={{
              position: "absolute",
              right: 0,
              height: "100%",
              justifyContent: "center",
              paddingLeft: 20,
            }}
            onPress={() => {
              handleConfirm();
            }}
          >
            <View
              style={{
                paddingHorizontal: 20,
                flexDirection: "row",
                paddingVertical: 6,
              }}
            >
              <svg.CheckSvg strokeColor={theme.COLORS.primary} />
            </View>
          </TouchableOpacity>
        }
      />
    );
  };

  const renderContent = () => {
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
        }}
      >
        <View style={{flex: 1, marginVertical: 10}}>
          <View style={{marginHorizontal: 15, marginTop: 10}}>
            <ApSearchInput onChange={handleSearch} />
          </View>

          <View
            style={{
              margin: 15,
              borderRadius: 10,
              marginTop: 10,
            }}
          >
            <FlatList
              data={data} // Pass your flat array of data
              keyExtractor={(item, index) => `${item.id}-${index}`} // Ensure unique keys
              renderItem={(
                {item} // Render each item in the list
              ) => (
                <ApRegCheckInput
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    paddingHorizontal: 15,
                    paddingVertical: 15,
                    borderBottomColor: "#8080801f",
                    borderBottomWidth: 1,
                    alignItems: "center",
                  }}
                  customLabel={
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: 10,
                      }}
                    >
                      <ApText
                        translateKey={item.label}
                        size="xs"
                        style={{marginRight: 10}}
                      >
                        {item.label}
                      </ApText>
                    </View>
                  }
                  defaultValue={isSelected(item)}
                  onChange={(val) => handleSelected(item)}
                  name="strategy"
                />
              )}
            />
          </View>
        </View>
      </ScrollView>
    );
  };

  return (
    <ApSafeAreaView>
      {!onDimiss && renderHeader()}
      {renderContent()}
      {!!strategies?.length && (
        <View style={{paddingHorizontal: 20, paddingVertical: 10}}>
          <ApButton
            round="lg"
            label="Confirm"
            labelTranslateKey="Confirm"
            onPress={() => handleConfirm()}
          />
        </View>
      )}
    </ApSafeAreaView>
  );
};

export default StrategySelection;
