export const API_KEY = "3712a0a739dcaf2ba1a78bcc573d";

export const BASE_URL = "https://be.bdd777.org/";;
// export const BASE_URL = "http://localhost/bddt";

export const PRICE_LIST_API = `https://bs.bdd777.org/v1/marketPrices`;

export const BN_PRICE_LIST_API = `https://api2.binance.com/api/v3/ticker/24hr`;

export const RequestStatus = {
  SUCCESS: 200,
  ERROR: "-1",
};

export const MasterData = {
  BB_TYPES: [
    {
      value: "1",
      label: "Open",
    },
    {
      value: "2",
      label: "Close",
    },
  ],
  MACD_TYPES: [
    {
      value: "1",
      label: "Support",
    },
    {
      value: "2",
      label: "MACD Crossing",
    },
    {
      value: "3",
      label: "MACD Crossing with trend",
    },
    {
      value: "4",
      label: "MACD Crossing against trend",
    },
  ],
  EMA_TYPES: [
    {
      value: "1",
      label: "Support",
    },
    {
      value: "2",
      label: "Low Crossing with trend",
    },
  ],
  KDJ_TYPES: [
    {
      value: "1",
      label: "Support",
    },
    {
      value: "2",
      label: "Crossing with trend",
    },
  ],
  RSI_TYPES: [
    {
      value: "1",
      label: "Support",
    },
    {
      value: "2",
      label: "Low Crossing with trend",
    },
    {
      value: "3",
      label: "High Crossing with trend",
    },
    {
      value: "4",
      label: "High Crossing against trend",
    },
  ],
  TAKE_PROFIT_MODE: [
    {
      value: "1",
      label: "Full",
    },
    {
      value: "2",
      label: "First & Last Entry",
    },
    {
      value: "3",
      label: "Last Entry",
    },
  ],
  INTERVALS: [
    {
      value: "0",
      label: "Never",
    },
    {
      value: "1m",
      label: "Every minute",
    },
    {
      value: "5m",
      label: "Every 5 min",
    },
    {
      value: "15m",
      label: "Every 15 min",
    },
    {
      value: "30m",
      label: "Every 30 min",
    },
    {
      value: "1h",
      label: "Every hour",
    },
    {
      value: "2h",
      label: "Every 2 hour",
    },
    {
      value: "4h",
      label: "Every 4 hour",
    },
    // {
    //   value: "6h",
    //   label: "Every 6 hour",
    // },
    {
      value: "8h",
      label: "Every 8 hour",
    },
    {
      value: "12h",
      label: "Every 12 hour",
    },
    {
      value: "1d",
      label: "Every day",
    },
  ],
  INTERVAL_TYPES: [
    {
      value: "1",
      label: "Left Trade",
    },
    {
      value: "2",
      label: "Right Trade",
    },
  ],
  INTERVAL_FREQUENCY_TYPES: [
    // {
    //   value: "0",
    //   label: "Off",
    // },
    {
      value: "1",
      label: "1m",
    },
    {
      value: "2",
      label: "Kline Close",
    },
  ],
  DIRECTION_TYPES: [
    {
      value: "1",
      label: "Long",
    },
    {
      value: "2",
      label: "Short",
    },
    {
      value: "3",
      label: "Both",
    },
  ],
  PT_ONE_DIRECTION_TYPES: [
    {
      value: "1",
      label: "Long",
    },
    {
      value: "2",
      label: "Short",
    },
    {
      value: "3",
      label: "Long or Short",
    },
  ],
  SPOT_DIRECTION_TYPES: [
    {
      value: "1",
      label: "USDT-based",
    },
    {
      value: "2",
      label: "Token-based",
    },
  ],
  PLATFORMS: [
    {
      value: "1",
      label: "Binance",
    },
    {
      value: "2",
      label: "Ouyi",
    },
  ],
};

export const MARKET_TYPES = [
  {
    value: "2",
    label: "Future",
    labelKey:"Future"
  },
  {
    value: "1",
    label: "Spot",
    labelKey:"Spot"
  },
];

export const TRADING_PLATFORMS = [
  // {
  //   value: "21",
  //   label: "FXTRADING",
  //   enabled: false,
  // },
  {
    value: "1",
    label: "Binance",
    enabled: true,
  },
  {
    value: "3",
    label: "OKX",
    enabled: true,
  },
  {
    value: "9",
    label: "TriiiX",
    enabled: true,
  },
  // {
  //   value: "5",
  //   label: "Bitget",
  //   enabled: true,
  // },
];

export const TRADING_PLATFORMS_OPTIONS = [
  {
    value: "1",
    label: "Binance",
    enabled: true,
  },
  {
    value: "3",
    label: "OKX",
    enabled: true,
  },
  {
    value: "9",
    label: "TriiiX",
    enabled: true,
  },
  // {
  //   value: "5",
  //   label: "Bitget",
  //   enabled: true,
  // },
];

export const LANGUAGES_TYPES = [
  {
    value: "zh_CN",
    label: "简体中文",
  },
  {
    value: "zh_TW",
    label: "繁體中文",
  },
  {
    value: "en",
    label: "English",
  },
]

export const LANGUAGES = [
  {
    id: "zh_CN",
    language: "简体中文",
    cardNumber: "**** **** **** 8456",
    cardAmount: "2 156.35",
    cardCurrency: "EUR",
    // cardIcon: require("../assets/cards/04.png"),
    languageCode: "simplified-chinese",
  },
  {
    id: "zh_TW",
    language: "繁體中文",
    cardNumber: "**** **** **** 8456",
    cardAmount: "2 156.35",
    cardCurrency: "EUR",
    // cardIcon: require("../assets/cards/04.png"),
    languageCode: "traditional-chinese",
  },
  {
    id: "en",
    language: "English",
    cardNumber: "**** **** **** 7895",
    cardAmount: "4 863.27",
    cardCurrency: "USD",
    // cardIcon: require("../assets/cards/06.png"),
    languageCode: "english",
  },
];