import { Formik, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import * as Yup from "yup";
import { components } from "../components";
import {
  ApButton,
  ApCheckInputAlt,
  ApConfirmModal,
  ApSafeAreaView,
  ApSymbolSelection,
  ApTextInput,
  ApToggleInput,
} from "../components/v1";
import { ApLeverageInput } from "../components/v1/input/leverageInput";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { ERROR_MSG } from "../constants/errors";
import { useTradingContext } from "../context";
import { useFollowContext } from "../context/follow";
import InfoSvg from "../svg/InfoSvg";
import { ApModalAlt } from "../components/Modal";
import { svg } from "../svg";
const FormSchema = Yup.object().shape({
  agree: Yup.bool().isTrue(ERROR_MSG.AgreeTerms).required(ERROR_MSG.AgreeTerms),
});

const StrategyDetails: React.FC = ({ navigation, route }: any) => {
  const bp_id = route?.params?.bp_id;
  const bex_id = route?.params?.bex_id;
  const selectedStrategries = route?.params?.selectedStrategries;
  const formRef = useRef<FormikProps<any>>();
  const [modal, setModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const {
    createBotExecutor,
    platform,
    market,
    botExecutorStrategySummary,
    botExecutorByID,
    loading,
    updateBotExecutor,
  } = useTradingContext();
  const [payload, setPayload] = useState<any>();
  const [showHint, setShowHint] = useState<boolean>(false);
  const [allowTargetProfit, setAllowTargetProfit] = useState(false)

  const { activeBotPlan } = useFollowContext();

  useEffect(() => {
    if(bex_id){
      if(botExecutorStrategySummary.target_pnl !== "0" || botExecutorStrategySummary.target_pnl_max !== "0"){
        setAllowTargetProfit(true)
      }
    }
  },[])

  const renderHeader = () => {
    return (
      <components.Header
        title="Bot Settings"
        goBack={true}
        titleTranlateKey="BotSettings"
      />
    );
  };

  const handleCreate = () => {
    let symbols: any[] = [];
    payload.symbols.map((item: any) => {
      symbols.push(item?.value);
    });

    createBotExecutor({
      bp_id: bp_id,
      exec_max: payload.exec_max,
      init_fund: payload.init_fund,
      leverage: payload.leverage,
      platform: platform,
      market: market,
      symbol: symbols.toString(),
      target_pnl: allowTargetProfit ? payload.target_pnl : "0",
      target_pnl_max: allowTargetProfit ? payload.target_pnl_max : "0",
      strategies: selectedStrategries,
    } as any);
  };

  const handleUpdate = () => {
    updateBotExecutor({
      bex_id: bex_id,
      bp_id: botExecutorStrategySummary.bp_id,
      // exec_max: payload.exec_max,
      exec_max: `${
        +payload.exec_max + +botExecutorStrategySummary?.exec_count
      }`,
      init_fund: payload.init_fund,
      leverage: payload.leverage,
      target_pnl: allowTargetProfit ? payload.target_pnl : "0",
      target_pnl_max: allowTargetProfit ? payload.target_pnl_max : "0"
    } as any );
  };

  const renderRoundsSection = (planType: string) => (
    <ApTextInput
      rightIcon={
        <ApText
          translateKey="Rounds"
          style={{
            backgroundColor: "#F37021",
            color: "#FFF",
            fontSize: 10,
            paddingHorizontal: 8,
            paddingVertical: 0.5,
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          }}
        />
      }
      border
      containerStyle={{
        borderBottomColor: planType == "0" ? "#FCF8F6" : "#ced6e182",
        paddingBottom: planType == "0" ? 0 : 15,
      }}
      width={130}
      height={25}
      label="Execution Round"
      labelTranslateKey="ExecutionRound"
      labelStyle={style.inputLabel}
      name="exec_max"
      inputStyle={{
        ...style.textInput,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
    />
  )

  const renderTargetProfitSection = () => (
    <>
      <ApToggleInput
        onToggle={(val) => {setAllowTargetProfit(val)}}
        noBorder={!allowTargetProfit ? true : false}
        labelTranslateKey="AllowTargetProfit"
        defaultValue={allowTargetProfit}
        label="Allow Target Profit"
        name="atp"
        labelStyle={style.inputLabel}
      />

      {allowTargetProfit && (
        <>
          <ApTextInput
            rightIcon={
              <ApText
                translateKey="USDT"
                style={{
                  backgroundColor: "#F37021",
                  color: "#FFF",
                  fontSize: 10,
                  paddingHorizontal: 8,
                  paddingVertical: 0.5,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                }}
              />
            }
            border
            width={130}
            height={25}
            labelTranslateKey="TargetProfit"
            label="Target Profit"
            labelStyle={style.inputLabel}
            name="target_pnl"
            inputStyle={{
              ...style.textInput,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />

          <ApTextInput
            rightIcon={
              <ApText
                translateKey="Rounds"
                style={{
                  backgroundColor: "#F37021",
                  color: "#FFF",
                  fontSize: 10,
                  paddingHorizontal: 8,
                  paddingVertical: 0.5,
                  borderTopRightRadius: 6,
                  borderBottomRightRadius: 6,
                }}
              />
            }
            border
            width={130}
            height={25}
            labelTranslateKey="RemainingTargetProfit"
            label="Remaining Target Profit"
            labelStyle={style.inputLabel}
            name="target_pnl_max"
            containerStyle={{
              borderBottomColor: "#FCF8F6",
              paddingBottom: 0,
            }}
            inputStyle={{
              ...style.textInput,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
        </>
      )}
    </>
  )

  return (
    <>
      <ApSafeAreaView>
        {renderHeader()}
        <View style={{ paddingHorizontal: 20, paddingVertical: 20 }}>
          <ApText
            translateKey={bp_id ? "TraderComment" : "SelectedCopyTrades"}
            style={{
              fontSize: 14,
              color: "#F37021",
              fontWeight: "500",
              paddingBottom: 10,
            }}
          />

          <View
            style={{
              backgroundColor: "#FCF8F6",
              padding: 10,
              marginBottom: 10,
              borderRadius: 10,
            }}
          >
            <ApText style={{ fontSize: 14 }}>
            {bp_id ? (
              <>
                {bex_id ? (
                  botExecutorStrategySummary.plan_desc === "" ? (
                    <ApText translateKey="NoTraderNote" />
                  ) : (
                    botExecutorStrategySummary.plan_desc
                  )
                ) : activeBotPlan?.plan_desc === "" ? (
                  <ApText translateKey="NoTraderNote" />
                ) : (
                  activeBotPlan?.plan_desc
                )}
              </>
            ) : (
              <>  
                {selectedStrategries?.map((item) => (
                  <View 
                    key={item.id}
                    style={{
                      marginBottom: 5,
                      display: "flex",
                    }}
                  >
                    <ApText size="xs" color="#F37021" font="bold">. {item.label_code}</ApText>
                  </View>
                ))}
              </>
            )}
            </ApText>
          </View>

          {bp_id ? (
          <View style={{ flexDirection: "row", marginBottom: 20 }}>
            <ApText
              translateKey="CommissionPercentage"
              style={{ fontSize: 12, color: "#F37021" }}
            />
            <ApText style={{ fontSize: 12, color: "#F37021" }}>
              {" : "}
              {bex_id
                ? (
                    parseFloat(botExecutorStrategySummary?.peer_fee) * 100
                  ).toFixed(2) + " %"
                : (parseFloat(activeBotPlan?.peer_fee) * 100).toFixed(2) + " %"}
            </ApText>
          </View>
          ) : null}

          <Formik
            enableReinitialize
            innerRef={formRef as any}
            validationSchema={FormSchema}
            initialValues={{
              init_fund: bex_id ? botExecutorByID.init_fund : "1",
              leverage: bex_id
                ? botExecutorByID.leverage
                : market === "1"
                ? "1"
                : "10",
              exec_max: bex_id
                ? +botExecutorByID?.exec_max - +botExecutorByID?.exec_count
                : "9999",
              symbols: [],
              target_pnl: bex_id ? botExecutorStrategySummary.target_pnl : "0",
              target_pnl_max: bex_id ? botExecutorStrategySummary.target_pnl_max : "0",
              agree: false,
            }}
            onSubmit={(val) => {
              setPayload(val);
              bex_id ? setUpdateModal(true) : setModal(true);
            }}
          >
            {(props: FormikProps<any>) => (
              <>
                <ApText
                  translateKey="ExecutionSettings"
                  style={{
                    fontSize: 14,
                    color: "#F37021",
                    fontWeight: "500",
                    paddingBottom: 10,
                  }}
                />

                <View
                  style={{
                    marginBottom: 20,
                    padding: 15,
                    backgroundColor: "#FCF8F6",
                    borderRadius: 10,
                  }}
                >
                  {bex_id ? (
                    <View
                      style={{
                        borderBottomWidth: 1,
                        paddingBottom: 12,
                        borderBottomColor: "#ced6e182",
                      }}
                    >
                      <View
                        style={{
                          alignItems: "flex-start",
                        }}
                      >
                        <ApText
                          translateKey="Symbol"
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                            marginBottom: 10,
                          }}
                        >
                          Symbol
                        </ApText>

                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#fff",
                            paddingVertical: 1,
                            paddingHorizontal: 10,
                            fontSize: 11,
                            borderRadius: 5,
                          }}
                        >
                          {botExecutorByID.symbol}
                        </ApText>
                      </View>
                    </View>
                  ) : (
                    <ApSymbolSelection name="symbols" />
                  )}

                  <ApTextInput
                    border
                    width={130}
                    height={25}
                    labelTranslateKey="FirstOrderMargin"
                    label="First Order Margin"
                    labelStyle={style.inputLabel}
                    name="init_fund"
                    inputStyle={{
                      ...style.textInput,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    rightIcon={
                      <ApText
                        style={{
                          backgroundColor: "#F37021",
                          color: "#FFF",
                          fontSize: 10,
                          paddingHorizontal: 8,
                          paddingVertical: 0.5,
                          borderTopRightRadius: 6,
                          borderBottomRightRadius: 6,
                        }}
                      >
                        USDT
                      </ApText>
                    }
                  />

                  {market === "1" ? (
                    <></>
                  ) : (
                    <ApTextInput
                      labelIcon={
                        <TouchableOpacity
                          onPress={() => setShowHint(true)}
                        >
                          <InfoSvg strokeColor="#F37021" />
                        </TouchableOpacity>
                      }
                      rightIcon={
                        <ApText
                          style={{
                            backgroundColor: "#F37021",
                            color: "#FFF",
                            fontSize: 10,
                            paddingHorizontal: 10,
                            paddingVertical: 0.5,
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6,
                          }}
                        >
                          X
                        </ApText>
                      }
                      border
                      width={130}
                      height={25}
                      label="Leverage"
                      labelTranslateKey="Leverage"
                      labelStyle={style.inputLabel}
                      name="leverage"
                      inputStyle={{
                        ...style.textInput,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                    />
                  )}

                  { bex_id ? (botExecutorStrategySummary?.plan_type == "0") 
                    && renderRoundsSection(botExecutorStrategySummary?.plan_type) 
                    : (activeBotPlan?.plan_type == "0") 
                    && renderRoundsSection(activeBotPlan?.plan_type)
                  }

                  { bex_id ? (botExecutorStrategySummary?.plan_type == "1") && renderTargetProfitSection() 
                    : (activeBotPlan?.plan_type == "1") && renderTargetProfitSection()
                  }

                  {/* <ApTextInput
                    rightIcon={
                      <ApText
                        translateKey="Rounds"
                        style={{
                          backgroundColor: "#F37021",
                          color: "#FFF",
                          fontSize: 10,
                          paddingHorizontal: 8,
                          paddingVertical: 0.5,
                          borderTopRightRadius: 6,
                          borderBottomRightRadius: 6,
                        }}
                      />
                    }
                    border
                    containerStyle={{
                      borderBottomColor: "#FCF8F6",
                      paddingBottom: 0,
                    }}
                    width={130}
                    height={25}
                    label="Execution Round"
                    labelTranslateKey="ExecutionsRemaining"
                    labelStyle={style.inputLabel}
                    name="exec_max"
                    inputStyle={{
                      ...style.textInput,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  /> */}
                </View>

                <ApCheckInputAlt
                  noBorder
                  name="agree"
                  labelTranslateKey="BotSettingTerms"
                  labelStyle={{
                    fontSize: 12,
                    color: "#F37021",
                    marginLeft: "10px",
                  }}
                />

                <View style={{ width: "100%" }}>
                  <ApButton
                    round="lg"
                    loading={loading}
                    onPress={() => props.handleSubmit()}
                    labelTranslateKey={bex_id ? "Update" : "SaveNew"}
                  />
                </View>
              </>
            )}
          </Formik>
        </View>
      </ApSafeAreaView>

      <ApConfirmModal
        show={modal}
        message="Confirm to create strategy?"
        messageTranslateKey="ConfirmCreateBot"
        onDimiss={(rs) => {
          if (rs) handleCreate();
          setModal(false);
        }}
      />

      <ApConfirmModal
        show={updateModal}
        message="Confirm to create strategy?"
        messageTranslateKey="ConfirmUpdateBot"
        onDimiss={(rs) => {
          if (rs) handleUpdate();
          setUpdateModal(false);
        }}
      />

      {showHint && (
        <ApModalAlt
          fallbackRoute={`StrategyDetails?bp_id=${bp_id}`}
          transparent={true}
          visible={showHint}
        >
          <>
            <TouchableOpacity
              onPress={() => {
                setShowHint(false);
              }}
              style={{ alignItems: "flex-end", padding: 15, paddingBottom: 0 }}
            >
              <svg.closeSvg />
            </TouchableOpacity>

            <View
              style={{
                width: "100%",
                paddingTop: 0,
                paddingVertical: 20,
                paddingHorizontal: 20,
              }}
            >
              <ApText
                style={{ marginTop: 5, marginLeft: 3, fontSize: 14 }}
                translateKey="LeverageHint"
              />
            </View>
          </>
        </ApModalAlt>
      )}
    </>
  );
};

export default StrategyDetails;

const style = StyleSheet.create({
  inputLabel: {
    ...theme.FONTS.H6,
    color: theme.COLORS.mainDark,
    // width: "50%",
  },
  textInput: {
    backgroundColor: "#DFE3EA",
    width: "20%",
    color: "#F37021",
    textAlign: "center",
    fontSize: 13,
    borderRadius: 6,
  },
});
